import { AppChannel } from '../constants';
import { ChannelTypes, FormTypes } from '../gql/graphql';
import { useStoreState } from './easyPeasyHooks';

function useTrack() {
  const { orderId, order } = useStoreState((state) => state.order);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);
  const channelType = useStoreState((state) => state.order.order?.dealer.channelType);
  const campaignMedium = new URLSearchParams(document.location.search.substring(1)).get('cm') || undefined;

  const track = (message: string, payload?: Object) => {
    const orderFinancingType = order?.dealer.financingType;
    const appChannel = order?.dealer?.isVirtualCard
      ? AppChannel.virtualCard
      : channelType === ChannelTypes.BrickAndMortar
      ? AppChannel.brickAndMortar
      : AppChannel.ecomm;
    const applicationId = order?.application?.lease?.id || order?.application?.loan?.id;
    const applicationNumber = order?.application?.lease?.displayId;
    const sourceWindowPath = window.location.pathname;
    const UI_redesign = 'UI_redesign';
    const defaultPayload = {
      formType: order?.dealer?.formType || (channelType === ChannelTypes.BrickAndMortar ? FormTypes.Long : FormTypes.Short),
      orderId,
      dealerId,
      orderFinancingType,
      appChannel,
      applicationId,
      applicationNumber,
      sourceWindowPath,
      UI_redesign,
      campaignMedium,
    };
    window.analytics.track(message, { ...defaultPayload, ...payload });
  };

  return track;
}

function usePage() {
  const { order } = useStoreState((state) => state.order);
  const channelType = useStoreState((state) => state.order.order?.dealer.channelType);

  const page = (name: string, payload?: object) => {
    const UI_redesign = 'UI_redesign';
    const defaultPayload = {
      formType: order?.dealer?.formType || (channelType === ChannelTypes.BrickAndMortar ? FormTypes.Long : FormTypes.Short),
      UI_redesign,
    };
    window.analytics.page(name, { ...defaultPayload, ...payload });
  };
  return page;
}

interface IdentifyArgs {
  customerId?: number;
  emailAddress?: string | undefined | null;
  cellPhone?: string;
  firstName?: string;
  lastName?: string;
}

function useIdentify() {
  const { role } = useStoreState((state) => state.auth);
  const identify = ({ customerId, emailAddress, cellPhone, firstName, lastName }: IdentifyArgs) => {
    if (customerId) {
      window.analytics.identify(`customer-${customerId}`, {
        id: customerId,
        phone: cellPhone,
        email: emailAddress,
        name: firstName && lastName ? firstName + ' ' + lastName : undefined,
        role,
      });
    } else {
      window.analytics.identify({
        email: emailAddress,
        phone: cellPhone,
        name: firstName && lastName ? firstName + ' ' + lastName : undefined,
        role,
      });
    }
  };

  return identify;
}

export { useTrack, usePage, useIdentify };
