import { EventObject, Typestate } from 'xstate';
import { HardDecline, Order, SoftDecline } from '../../gql/graphql';

// Context Types
export type LongFormMachineContext = {
  order?: Order | null;
  transitionedFrom?: LongFormStates | null;
  highestSupportedApplicationMilestone?: number | null;
  hardDeclineError?: HardDecline;
  softDeclineError?: SoftDecline;
  skipToPage?: string | null;
  isReadOnly?: boolean | null;
};

// State Types
export enum LongFormStates {
  Entry = 'Entry',
  PersonalInformation = 'personalInformation',
  ContactInformation = 'contactInformation',
  BillingAddress = 'billingAddress',
  IncomeSource = 'incomeSource',
  BankingInformation = 'bankingInformation',
  DebitCardInformation = 'debitCardInformation',
  ReviewAndSubmit = 'reviewAndSubmit',
  AmendAndResubmit = 'amendAndResubmit',
  TransferToRapid = 'transferToRapid',
  PreApproval = 'preApproval',
  Declined = 'declined',
  InternalError = 'internalError',
  ApprovalConfirmation = 'approvalConfirmation',

  // Errors Found States
  BankAccount = 'bankAccount',

  // info pages
  CustomerIdProgram = 'CustomerIdProgram',
  TermsAndConditions = 'TermsAndConditions',
  PrequalificationNotice = 'PrequalificationNotice',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
}

export interface LongFormMachineStatesSchema {
  [LongFormStates.Entry]: {};
  [LongFormStates.PersonalInformation]: {};
  [LongFormStates.ContactInformation]: {};
  [LongFormStates.BillingAddress]: {};
  [LongFormStates.IncomeSource]: {};
  [LongFormStates.BankingInformation]: {};
  [LongFormStates.DebitCardInformation]: {};
  [LongFormStates.ReviewAndSubmit]: {};
  [LongFormStates.AmendAndResubmit]: {};
  [LongFormStates.TransferToRapid]: {};
  [LongFormStates.CustomerIdProgram]: {};
  [LongFormStates.TermsAndConditions]: {};
  [LongFormStates.PrequalificationNotice]: {};
  [LongFormStates.AuthorizedUserTerms]: {};
  [LongFormStates.PreApproval]: {};
  [LongFormStates.Declined]: {};
  [LongFormStates.InternalError]: {};
}

export interface LongFormMachineStates extends Typestate<LongFormMachineContext> {
  type: LongFormMachineStatesSchema;
}

// Event Types
export enum LongFormEvents {
  NEXT = 'NEXT',
  BACK = 'BACK',
  CancelButtonClicked = 'CancelButtonClicked',

  // info page events
  VIEW_CUSTOMER_ID_PROGRAM = 'ViewCustomerIdProgram',
  VIEW_TERMS_AND_CONDITIONS = 'ViewTermsAndConditions',
  VIEW_PREQUALIFICATION_NOTICE = 'ViewPrequalificationNotice',
  VIEW_AUTHORIZED_USER_TERMS = 'ViewAuthorizedUserTerms',

  // pre-approved page events
  APPROVED = 'Approved',
  DECLINED_OR_ERROR = 'DeclinedOrError',
}

type LongFormEventsTypeSchema =
  | LongFormEvents.NEXT
  | LongFormEvents.BACK
  | LongFormEvents.CancelButtonClicked
  | LongFormEvents.VIEW_CUSTOMER_ID_PROGRAM
  | LongFormEvents.VIEW_TERMS_AND_CONDITIONS
  | LongFormEvents.VIEW_PREQUALIFICATION_NOTICE
  | LongFormEvents.VIEW_AUTHORIZED_USER_TERMS
  | LongFormEvents.APPROVED
  | LongFormEvents.DECLINED_OR_ERROR;

export interface LongFormMachineEvents extends EventObject {
  type: LongFormEventsTypeSchema;
  data?: any;
}

export enum LongFormGuardsType {
  LEASE_DECLINED = 'LeaseDecline',
  LOAN_DECLINED = 'LoanDecline',
  LTO_INELIGIBLE = 'LTOIneligible',
  APP_APPROVED = 'AppApproved',
  GENERAL_APPLY_ERRORS = 'GeneralApplyErrors',
  SOFT_DECLINE = 'SoftDecline',
}
type LongFormGuardsTypeSchema =
  | LongFormGuardsType.LEASE_DECLINED
  | LongFormGuardsType.LOAN_DECLINED
  | LongFormGuardsType.LTO_INELIGIBLE
  | LongFormGuardsType.GENERAL_APPLY_ERRORS
  | LongFormGuardsType.SOFT_DECLINE
  | LongFormGuardsType.APP_APPROVED;

export interface LongFormMachineGuards extends EventObject {
  type: LongFormGuardsTypeSchema;
}
