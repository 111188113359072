import React, { useEffect, useState } from 'react';
import { AppConfig } from './app.config';
import CustomErrorBoundary from './components/customErrorBoundary/custom-error-boundary';
import CustomSecurityErrorBoundary, { SecurityErrorType } from './components/customSecurityErrorBoundary/custom-security-error-boundary';
import KoalafiInitialLoader from './components/koalafi-initial-loader';
import { acceptedDomain, inIframe, setupSession } from './helpers';
import { setSessionId } from './helpers/logging/logging-helper';
import { modalLoaderHelper } from './helpers/modal/modal.helper';
import { useStoreConditions } from './helpers/neuroId/neuroId.conditions.helper';
import { initializeNeuroIdSession, shouldProcessNeuroId } from './helpers/neuroId/neuroId.helper';
import { currentDomain } from './helpers/window/window.helpers';
import { useSessionProperies, useTagUser } from './hooks/dtrum';
import { useStoreActions, useStoreState } from './hooks/easyPeasyHooks';
import { useTrack } from './hooks/segment';
import AppContent from './pages/app-content/app-content';
import NotAllowedToCreateNewApplications from './pages/notAllowedToCreateNewApplications/NotAllowedToCreateNewApplications';
import { getDealerCookie, Role } from './helpers/cookies/cookie.helper';

function App() {
  // Redux Actions --- will use xstate context in future
  const { queryFeatureFlags, queryHighestSupportedApplicationMilestone } = useStoreActions((actions) => actions.featureFlags);
  const { queryOrderPreAuth, setOrderId, createOrder } = useStoreActions((actions) => actions.order);
  // Redux State -- will use xstate context in future
  const { featureFlags, highestSupportedApplicationMilestone } = useStoreState((state) => state.featureFlags);
  const { orderId, order, error, neuroIdIdentityId } = useStoreState((state) => state.order);
  const { forbiddenError, unauthorizedError, hybridVerificationAttempted, hybridVerificationSuccessful } = useStoreState((state) => state.auth);
  const orderCustomerId = useStoreState((state) => state.order?.order?.application?.customer?.id);
  const appMode = useStoreState((state) => state.order.appMode);
  const domains = useStoreState((state) => state.order.order?.dealer.domains);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);

  const { setLaunchPage, setRole, verifyHybridToken } = useStoreActions((actions) => actions.auth);
  // get and set orderId
  const orderIdParams = new URLSearchParams(document.location.search.substring(1)).get('orderId');
  const dealerIdParams = new URLSearchParams(document.location.search.substring(1)).get('dealerPublicId');
  const hybridTokenParams = new URLSearchParams(document.location.search.substring(1)).get('hybridToken');
  const launchPageFromParams = new URLSearchParams(document.location.search.substring(1)).get('launchPage');
  const reviewPageFromParams = new URLSearchParams(document.location.search.substring(1)).get('reviewPage');
  const dtrumProps = useSessionProperies();
  const dtrumUser = useTagUser();
  const track = useTrack();

  const isModal = inIframe();

  const isNeuroIdEnabled = shouldProcessNeuroId(useStoreConditions());
  const [isNeuroIdInitialized, setIsNeuroIdInitialized] = useState(false); // do only once

  // This always needs to be in App.tsx - can't query order without it.
  const pdiFromParams = new URLSearchParams(document.location.search.substring(1)).get('pdi');

  if (orderIdParams && !orderId) {
    setOrderId(orderIdParams);
  }
  if (pdiFromParams) {
    sessionStorage.setItem('publicDealerId', pdiFromParams);
  }

  if (order?.dealer) {
    if (!orderIdParams && orderId) {
      //Set orderID in the query params if it's not already set
      const url = new URL(document.location.href);
      url.searchParams.set('orderId', orderId);
      window.history.pushState({}, '', url);
    }

    if (orderIdParams && orderId && orderId !== orderIdParams) {
      //VC creates a new order everytime the page is refreshed so we want to update the query params to match orderID
      const url = new URL(document.location.href);
      url.searchParams.set('orderId', orderId);
      window.history.pushState({}, '', url);
    }
  }

  const appLevelError = (errMessage: string) => {
    // Using current domain because order has not yet loaded so we might not have domains
    const sendDomain = domains ? domains : [currentDomain()];
    modalLoaderHelper({ stopSpinner: true }, sendDomain);

    return (
      <CustomErrorBoundary
        data-testid="error-boundary"
        error={errMessage}
        body={`We are unable to proceed with your application due to a setting in your browser.`}
        additionalBody={`If you have disabled cookies or are browsing in Private Mode, please try enabling cookies or turning off Private Mode and retrying.`}
        buttonText={`Return to store`}
      />
    );
  };

  const securityLevelError = (errMessage: string, secErrType: SecurityErrorType) => {
    // Using current domain because order has not yet loaded so we might not have domains
    const sendDomain = domains ? domains : [currentDomain()];
    modalLoaderHelper({ stopSpinner: true }, sendDomain);

    return <CustomSecurityErrorBoundary error={errMessage} errorType={secErrType} />;
  };

  useEffect(() => {
    let role = getDealerCookie();
    if (!role) {
      role = Role.Customer; // if cookie is unreadable, or otherwise unusable, assume customer
    }
    setRole(role);
    track(`User role: ${role}`);

    const seshId = setSessionId();
    track(`User session id (${seshId}`, {
      sessionId: seshId,
    });

    // Sometimes we want to track how the user navigated to the UI. Anything creating
    // links to the UI, e.g. digital-communications-service for the 'view virtual card'
    // email, can set the 'referrer' query param to an indicative value which we can
    // inspect here to publish an appropriate Segment event.
    const referrer = new URLSearchParams(document.location.search.substring(1)).get('referrer');
    switch (referrer) {
      // Recognized param values are purposefully terse since they are visible to
      // end users.
      case 'vce': // 'virtual card email'
        track('Return To Virtual Card Email CTA Clicked');
        break;
      // Tracking a default case (no or unrecognized referrer) has not been deemed
      // valuable.
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hybridTokenParams) {
      track('Attempting Hybrid Verification');
      verifyHybridToken({ hybridToken: hybridTokenParams });
    }
  }, [hybridTokenParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // fetch order with orderId
  useEffect(() => {
    // Leaving this here for now
    if (!featureFlags) {
      queryFeatureFlags();
    }

    // Moving if logic to external function to be added into machine at later time
    // initialOrderAction({ orderId, dealerIdParams }, { createOrder, queryOrder }, order);

    if (!orderIdParams && !orderId && dealerIdParams) {
      createOrder({});
    }

    // only query order if !order, app was calling query multiple times
    // if hybrid token is present in the URL, we need to wait for verifyHybridToken to complete before querying the order
    if (orderId && !order && (!hybridTokenParams || hybridVerificationAttempted)) {
      if (hybridVerificationAttempted) {
        hybridVerificationSuccessful ? track('Hybrid Verification Successful') : track('Hybrid Verification Failed');
      }
      queryOrderPreAuth(orderId);
      setupSession(orderId!);
    }

    if (order) {
      sessionStorage.setItem('publicDealerId', order.dealer.publicDealerId);

      // send session attributes to Dynatrace - may or may not be available, but we'll try anyways
      dtrumProps({
        firstname: order?.details?.customer?.firstName as string,
        lastname: order?.details?.customer?.lastName as string,
        emailaddress: order?.details?.customer?.emailAddress as string,
        publicdealerid: order?.dealer?.publicDealerId as string,
        storename: order?.dealer?.storeName as string,
        orderid: orderId as string,
      });

      // identify user tag for Dynatrace session
      if (order?.details?.customer?.emailAddress) {
        dtrumUser(order?.details?.customer?.emailAddress as string);
      }
    }

    if (launchPageFromParams) {
      setLaunchPage(launchPageFromParams);
    } else {
      setLaunchPage('checkout');
    }
  }, [orderId, dealerId, hybridTokenParams, hybridVerificationAttempted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!document.fonts) {
      document.fonts.forEach((fontFace) => {
        if (fontFace.status !== 'loaded' && fontFace.status !== 'loading') {
          fontFace.load();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!order && !highestSupportedApplicationMilestone && highestSupportedApplicationMilestone !== 0) {
      queryHighestSupportedApplicationMilestone(order.dealer.id);
    }
  }, [order, highestSupportedApplicationMilestone, queryHighestSupportedApplicationMilestone]);

  useEffect(() => {
    if (isNeuroIdEnabled) {
      if (!isNeuroIdInitialized) {
        setIsNeuroIdInitialized(true);
        initializeNeuroIdSession(AppConfig.env !== 'prod');
        nid('setVariable', 'dealerId', dealerId?.toString());
      }
    }
  }, [isNeuroIdEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // used to support neuroId on refreshes
    if (isNeuroIdEnabled && isNeuroIdInitialized) {
      if (neuroIdIdentityId) nid('identify', neuroIdIdentityId);
      if (orderCustomerId) nid('setRegisteredUserId', orderCustomerId.toString());
    }
  }, [isNeuroIdEnabled, isNeuroIdInitialized, neuroIdIdentityId, orderCustomerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAppContents = () => {
    if (domains && !acceptedDomain(domains)) {
      return appLevelError('Not an acceptable domain.');
    } else if (forbiddenError) {
      return securityLevelError(`Error: ${forbiddenError.displayText}`, SecurityErrorType.Forbidden403);
    } else if (unauthorizedError) {
      return securityLevelError(`Error: ${unauthorizedError.displayText}`, SecurityErrorType.Unauthorized401);
    } else if (error && !order) {
      return appLevelError(`Error: ${error.displayText}`);
    } else if (!order && (orderIdParams || dealerIdParams)) {
      if (!isModal) {
        return <KoalafiInitialLoader />;
      } else {
        return <div data-testid="initial-loader" />;
      }
    } else if (!!order && highestSupportedApplicationMilestone !== null) {
      if (!order.dealer.isAllowedToCreateNewApplications) {
        return <NotAllowedToCreateNewApplications />;
      } else {
        return (
          <AppContent
            orderID={orderIdParams!}
            orderDetails={order}
            appMode={appMode!}
            highestSupportedApplicationMilestone={highestSupportedApplicationMilestone!}
            reviewPage={!!reviewPageFromParams}
          />
        );
      }
    } else {
      return <div data-testid="initial-loader" />;
    }
  };

  return getAppContents();
}

export default App;
